@import url("https://use.typekit.net/upv5wkd.css");

body {
  margin: 0;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8ef;
}

@media screen and (max-width: 900px) {
  body {
    font-size: 16px;
  }
}
