/* colors */
:root {
  --color-show-all: rgb(0, 0, 0);
  --color-show-all-active: rgba(0, 0, 0, 0.6);
  --color-art: rgb(171, 134, 182);
  --color-art-active: rgba(171, 134, 182, 0.6);
  --color-active: rgb(56, 163, 92);
  --color-active-active: rgba(56, 163, 92, 0.6);
  --color-craft: rgb(228, 100, 98);
  --color-craft-active: rgba(228, 100, 98, 0.6);
  --color-comp: rgb(206, 132, 3);
  --color-comp-active: rgba(206, 132, 3, 0.6);
  --color-lifestyle: rgb(65, 139, 251);
  --color-lifestyle-active: rgba(65, 139, 251, 0.6);
}


// Content skips
:root .skipnav {
	display: block;
}

.skipnav {
	position: absolute;
	display: none;
	text-align: left;
	margin: 0;
	padding: 0;

	a {
		width: 26em;
		display: block;
		color: #fff;
		background: #333;
		text-decoration: none;
		padding: 5px;
		position: absolute;
		left: -1000em;
		top: 0;

		&:focus,
		&:active {
			z-index: 99;
			top: 0;
			left: 0;
			border: solid #777 2px;
			color: #fff;
			background: #333 !important;
		}

	}
}

/* General Styles */
p {
  font-size: 1em;
  font-weight: 300;
  line-height: 1.6;
}

@media screen and (max-width: 900px) {
  p {
    line-height: 25px;
  }
}
/* 
h1,
h2,
h3,
h4,
h5 {
}

ul {
}

ol {
}
*/
a {
  border: 2px solid transparent;
  cursor: pointer;
}

.img-btn {
	height: 100%;
	display: block;
}

button {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  color: #000;
  border: 1px solid transparent;
}

/* button:focus {
    border: 1px solid rgb(164, 205, 255);
} */

button p {
  margin: 0;
}
/* 
button:hover p {
    border-bottom: 1px solid #000;
} */

select {
  font-size: 1em;
  font-weight: 500;
}

label {
  font-size: 1rem;
  font-weight: 500;
}

/* Utilities */
.nobullets {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nobutton {
  outline: none;
  border: none;
  appearance: unset;
  background-color: unset;
}

.appbutton {
  outline: none;
  border: none;
  background-color: unset;
}

.noselect {
  border: none;
  background: none;
  outline: none;
  appearance: none;
}

.noinput {
  border: none;
  outline: none;
}

.nolink {
  text-decoration: none;
  color: unset;
  border: unset;
}

.nolist {
  list-style: none;
  padding: 0;
  margin: 0;
}

.hidden {
  display: none !important;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* Sitewide */
.btn {
  display: flex;
  align-items: center;
  border-radius: calc(74px / 2);
  padding: 0 1.5em;
  margin-top: 2em;
}

.btn p {
  margin: 0;
}

/** Focus Styles **/
button:focus-visible {
	outline: 2px solid #144ac7 !important;
}

a:focus-visible {
	outline: 2px solid #144ac7 !important;
}

input:not(#filter-buttons input):not(.event-datepicker):focus-visible {
	outline: 2px solid #144ac7 !important;
}

.btn:focus-visible {
  outline: 2px solid #144ac7 !important;
}
////////////////

.white-button {
  background: #fff;
  border: 1px solid #000;
}

.white-button:hover p {
  color: #159b41;
}

.green-button {
  background: #f8f8ef;
}

.green-button:hover {
  background-color: #6aa869;
}

.link {
  display: inline-block;
  border-radius: calc(74px / 2);
  padding: 0 1.5em;
  margin-top: 2em;
}

.link:focus {
  border: 1px solid rgb(104, 161, 247);
}

.big-btn {
  border: 1px solid #000;
  height: 74px;
}

@media screen and (max-width: 900px) {
  .big-btn {
    height: 57px;
  }
}

.big-btn:focus {
  border: 1px solid rgb(104, 161, 247);
}

.center-text {
  text-align: center;
}

h1 {
  font-size: 1.75em;
  font-weight: 700;
}

@media screen and (max-width: 900px) {
  h1 {
    font-size: 1.563em;
  }
}

.app {
  max-width: 1600px;
  margin: 0 auto;
}

.flex-center {
  align-self: center;
}

.checkbox {
  cursor: pointer;
  border: 1px solid #000;
  padding: 0 1em;
  border-radius: 50px;
  margin: 0 0.5em;
  min-height: 44px;
}

@media screen and (max-width: 900px) {
  .checkbox {
    min-height: 38px;
  }
}

.checkbox p {
  margin: 0;
  font-weight: 400;
}

.checkbox:hover p {
  color: #159b41;
}

.checkbox-is-active {
  background-color: #aadaa9;
}

.checkbox-is-active p {
  color: #000;
}

.display-block {
  display: block;
}
.display-none {
  display: none;
}

.close-btn {
  border: 1px solid transparent;
  padding: 0.5em;
  border-radius: 15px;
  cursor: pointer;
}
.close-btn svg {
  font-size: 2em;
}

.close-btn:focus {
  border: 1px solid rgb(104, 161, 247);
}

.modal-btn {
  cursor: pointer;
  border: 1px solid transparent;
}
.modal-btn:focus {
  border: 1px solid rgb(104, 161, 247);
}

.bold {
  font-weight: bold;
}

/* Kategoriar */
$buttons: "show-all", "art", "active", "comp", "craft", "lifestyle";
.category-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: 900px) {
    justify-content: flex-start;
  }
}
.category-buttons button {
  background-color: #fff;
  border: solid 4px transparent;
  border-radius: 35px;
  height: 3.5rem;
  padding: 0 2em;
  margin: 1em;
  transition: background-color 350ms ease;
  cursor: pointer;

  &:focus-visible {
	outline-offset: 0.25rem;
  }

  @each $btn in $buttons {
    &.#{$btn}-button {
      @if $btn == "show-all" {
        color: var(--color-#{$btn});
      }
      border-color: var(--color-#{$btn});

      &:hover,
      &.current-button {
        background-color: var(--color-#{$btn});
        @if $btn == "show-all" {
          color: #fff;
        }
      }

      &.current-button:hover {
        background-color: var(--color-#{$btn}-active);
      }
    }
  }

  @media screen and (max-width: 1400px) {
    margin: 0.5em;
  }
  @media screen and (max-width: 900px) {
    margin: 0.2em;
    border-width: 2px;
    height: 38px;
    padding: 0 1em;
  }
}

.prev,
.next {
  cursor: pointer;
  border: 1px solid transparent;
}

@media screen and (max-width: 900px) {
  .prev {
    margin-right: 1em;
  }
  .next {
    margin-left: 1em;
  }
}

.prev:dir(rtl),
.next:dir(rtl) {
  transform: rotate(180deg);
}

.prev svg,
.next svg {
  margin: 0 1em;
  font-size: 1.75em;
}

@media screen and (max-width: 900px) {
  .prev svg,
  .next svg {
    margin: 0;
    font-size: 1.5em;
  }
}

.prev.disabled,
.next.disabled {
  pointer-events: none;
  color: #999;
}

.prev:focus,
.next:focus {
  border: 1px solid rgb(164, 205, 255);
}

.pagination-item {
  margin: 0 0.5em;
  width: 2rem;
  cursor: pointer;
  border: 1px solid transparent;
  color: #555;
}

.pagination-item:focus {
  border: 1px solid rgb(164, 205, 255);
}

.pagination-item.active {
  font-weight: bold;
  pointer-events: none;
  color: #000;
}
.pagination-item:disabled {
  pointer-events: none;
  color: #999;
}

.pagination-first,
.pagination-last {
  margin: 0;
  width: 2.5em;
  cursor: pointer;
  border: 1px solid transparent;
  color: #999;
  display: flex;
}

.pagination-first:disabled,
.pagination-last:disabled {
  pointer-events: none;
  color: #999;
}

.ellipses-left {
  margin: 0;
  margin-right: 1em;
}
.ellipses-right {
  margin: 0;
  margin-left: 1em;
}

@media screen and (max-width: 900px) {
  .pagination-first,
  .pagination-last,
  .ellipses-left,
  .ellipses-right {
    display: none;
  }
}

.reset-btn {
  border: 1px solid transparent;
}

.reset-btn p {
  text-decoration: underline;
}

.reset-btn:hover {
  border: 1px solid rgb(104, 161, 247);
}

.activity-item {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 0.75em;
  background-color: #FBFBF6;
  border: 1px solid #000;
  padding: 1em;
  transition: background-color 250ms ease;

  &:hover {
	background-color: #aadaa9;
  }

  @media screen and (max-width: 900px) {
	padding-top: 0.2em;
	height: 100px;
	min-height: unset;
  }
}

.activity-item h3 {
  display: inline;
  border-bottom: 1px solid transparent;
}

.search-button {
  border: 1px solid #000;
  border-radius: 100px;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 50px;
  margin: 0 0.5em;
  cursor: pointer;
  @media screen and (max-width: 900px) {
	  margin: 0 3em 0 0;
	  height: 38px;
  }
  @media screen and (max-width: 400px) {
	margin: 0;
  }
}

.search-button:hover {
  color: #159b41;
}

.search-button svg {
  font-size: 1.1125em;
}



/* Framside */
.about-btn {
  margin: 0 4em;
}
@media screen and (max-width: 1400px) {
  .about-btn {
    margin: 0 2em;
  }
}

.fritidskort-btn {
  background-color: #aadaa9;
  border-radius: calc(74px / 2);
  padding: 0 1.5em;
  border: 1px solid transparent;

  cursor: pointer;
}
@media screen and (max-width: 900px) {
  .fritidskort-btn {
    margin: 1em 0 2em;
  }
}

.fritidskort-btn:dir(rtl) {
  margin-left: 0;
  margin-right: 120px;
}


/* About */
/* .isActive {

} */

/* Forslag til aktivitet */

.feedback {
  padding-left: 1em;
  display: none;
}

.feedback.invalid {
  display: block;
  color: red;
}

.suggest-dropdown {
  padding: 0 1em;
  margin-top: 0.5em;
}

.suggest-form {
  border: 1px solid transparent;
}

.suggest-form:focus {
  border: 1px solid rgb(104, 161, 247);
}

/* Enkel aktivitet */

/* Transitions */
.dropdown-enter {
  opacity: 0;
}

.dropdown-enter.dropdown-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.dropdown-exit {
  opacity: 1;
}

.dropdown-exit.dropdown-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.btn-active svg {
  transform: rotate(180deg);
  transition: transform 250ms ease;
}
.btn-diabled svg {
  transition: transform 250ms ease;
}

.access__control {
  height: 44px;
}

@media screen and (max-width: 900px) {
  .access__control {
    height: 38px;
  }
}

/* Modal Checkboxes */
.modal-main {
  max-width: 800px;
}

.modal-checkbox {
  height: 68px;
  border: 1px solid #000;
  border-radius: 68px;
  padding: 0.5em;
  margin: 0.5em 1em;
  font-weight: medium;
}

.checkbox-isactive {
  background-color: #aadaa9;
  border-color: #5ea35c;
}

.a11y-button-active {
  background-color: #aadaa9;
}

.a11y-modal-main {
  max-width: 1100px;
}

.modal.display-block {
    margin: 0 !important;
}

#filter-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: auto;

  > div,
  > button {
    margin: 8px 8px 8px 0;

    > button {
      margin: 0;
    }
  }
  .county {
    width: 280px;
    .county__control {
      border-radius: 50px;
	  border-color: #000;
	  cursor: pointer;
    }
	.county__placeholder {
		color: #000;
	}
	.county__indicator-separator {
		background-color: #000;
	}
	.county__indicator {
		color: #000;
	}
  }
  .age {
    width: 187px;
    border-radius: 50px;
    .age__control {
      border-radius: 50px;
	  border-color: #000;
	  cursor: pointer;
    }
	.age__placeholder {
		color: #000;
	}
	.age__indicator-separator {
		background-color: #000;
	}
	.age__indicator {
		color: #000;
	}
  }

  @media screen and (max-width: 900px) {
    justify-content: flex-start;
    .county,
    .age {
      width: 140px;
    }
  }
}

/* Events */
.event-toggle {
  color: #000;
  cursor: pointer;
  
  &:focus-visible {
	border-radius: 0;
  }

}

.toggles {
  height: 44px;
  padding: 0 1em;
  border: 2px solid #000;
  display: flex;
  transition: all 400ms ease;

  p {
    align-self: center;
  }
}

.toggles.active {
  color: #000;
  background-color: #aadaa9;
  border-radius: 24px;
}

.list-toggle {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.list-toggle.active {
  border-right-color: #000;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}

.calendar-toggle {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}
.calendar-toggle.active {
  border-left-color: #000;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}

.event-item {
  width: 100%;
}

/* Event Selects */
.event-county {
  width: 30%;
  height: 44px;
}

.event-age {
  width: 30%;
  height: 44px;
}

/* Event Calendar */
.react-calendar {
  width: 100%;
}

.react-calendar__tile {
  width: 200px;
  min-height: 100px;
  display: flex;
  flex-flow: column nowrap;
  background-color: #fff;
  border-right: 1px solid #d1cbb8;
  border-bottom: 1px solid #d1cbb8;
}

.react-calendar__tile:nth-child(7n) {
  border-right: unset;

  abbr {
    color: red;
  }
}

// .react-calendar__tile:nth-child(n+31) {
//     border-bottom: unset;
// }

.event-calendar-items {
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
    border-bottom: 1px solid #707070;
    padding: 6px 0;
    font-size: 14px;
    margin: 0 4px 0;

    &:hover {
      font-weight: bold;
    }
  }

  a:only-child p {
    white-space: normal;
    border-bottom: none;
    margin: 0 !important;
  }

  a:last-child p {
    border-bottom: none;
    margin: 0 4px 1em;
  }
}

.react-calendar__month-view__weekdays__weekday {
  display: none;
}

.react-calendar__navigation {
  text-align: center;
  margin: 0 0 1em;

  button {
    background-color: #ffffff;
  }
}

.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0.3;
  border-bottom: 1px solid #d1cbb8;
}

.react-calendar__navigation {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  margin: 0 auto 3em;
  height: 94.8px;
}

.react-calendar__navigation__label {
  flex-grow: 0 !important;
  font-size: 30px;
  font-weight: bold;
  width: 300px;
  text-transform: capitalize;
}

.react-calendar__navigation__arrow {
  font-size: 35px;
}

.react-calendar__tile {
  text-align: left;
}

.MuiTooltip-tooltip {
  background-color: #000 !important;
  padding: 0 !important;
  border-radius: 15px !important;
  font-family: "poppins", sans-serif !important;
}

.hover-card-container {
  cursor: pointer;
}

// Admin Button
.admin-link {
    color: #000;
    border: none;
    text-decoration: none;

    p {
        margin: 0;
    }
}

// Login Page
.login-page {
    margin: 8em 4em 4em;
}

.login-feedback {
	color: red;
	font-size: 0.8em;
}

.login-content-wrapper {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-around;
}

// Admin Area 
.page.admin-panel {
	max-width: 1400px;
	margin: 8em auto 4em;
	padding: 0 2em;
	min-height: calc(90vh - 300px);
}

.admin-panel {
}

.admin-area {
	display: flex;
	gap: 20px;
	margin: 0 auto;
	justify-content: space-between;

	@media screen and (max-width: 900px) {
		flex-flow: column nowrap;
	}
}
.admin-content {
	max-width: 800px;

	@media screen and (max-width: 900px) {
		order: 2;
		
		h1 {
			font-size: 1.25rem;
		}
	}
}
.admin-welcome-msg {
	width: 335px;
	
	@media screen and (max-width: 900px) {
		width: auto;
		order: 1;
	}
}

.applications-wrapper {
	margin: 0 auto;
}


// Admin List
.admin-event-list {
	list-style: none;
}

.admin-event-list-key {
	list-style: none;
}

.admin-event-list-item {
	list-style: none;

	display: flex;
	justify-content: space-between;

	p {
		margin: 0;
	}
}

// Privacy Policy
.personvern-page {
	margin: 8em 4em 4em;
}

// Organizer page
.organizer-page {
	margin: 8em auto 4em;
	padding: 0 2em;
	min-height: calc(90vh - 300px);
	max-width: 1400px;
}

.logout-fb {
	position: absolute;
	right: 3%;
	top: 60px;
}

// Admin filters
.admin-filters {
	input[type='checkbox'] {
		height: 31px;
		width: 31px;
		margin: 0;
	}

	label {
		font-size: 16px;
	}

	section {
		display: flex;
		flex-flow: row wrap;
		gap: 20px;
	}

}

.admin-filters {
	border: 1px solid #707070;
	border-radius: 31px;
	max-width: 531px;
}

.filter-btn {
	height: 61px;
	width: 531px;
	border-radius: 31px;
	background-color: #fff;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	font-weight: bold;
	padding: 0 0 0 2.5rem;

	@media screen and (max-width: 900px) {
		width: 100%;
	}

	svg {
		margin-left: 1em;
		transition: all 300ms;
	}
}

.admin-search {
	margin: 1em 0;
	position: relative;
}

.filters:first-child {
	margin-top: 0;
	margin-bottom: 1em;
}

.filters {
	margin: 1em 0;
}

.admin-filter-form {
	padding: 0 2.5em 1em;
	background-color: #fff;
	border-bottom-left-radius: 31px;
	border-bottom-right-radius: 31px;

	label, input {
		font-weight: 300;
	}

	form .filter-locations div {
		display: flex;
		align-items: center;
	}

	.filter-locations {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;

		label {
			margin: 0 0 0 8px;
		}
		input[type="checkbox"] {
			border-radius: 6px;
		}
	}
	.filter-type {
		select {
			width: 230px;
			height: 31px;
			border-radius: 6px;
			background-color: #fff;
			border: 1px solid #707070;
			font-size: 1.2rem;
			font-weight: 300;
		}
	}
	.filter-date {
		.react-datepicker__input-container input {
			width: 230px;
			height: 29px;
			font-size: 1.2rem;
			border-radius: 6px;
			border: 1px solid #707070;
			outline: none;
			padding: 0;
		}
	}
	.filter-do {
		button {
			font-size: 1em;
			font-weight: 300;
			border-radius: 6px;
			border: 1px solid #707070;
		}
		button:first-child {
			background-color: #B4D8AD;
		}
		button:last-child {
			background-color: #fff;
			margin-left: 40px;
		}
	}
}

.admin-events {
	ul {
		padding: 0;
		list-style: none;
	}
}

.admin-event-list-key {
	display: flex;
	flex-flow: column nowrap;
	margin: 10px;
	
	.organizer-btn {
		border-radius: 6px;
		background-color: #B4D8AD;	
		flex: 1 0 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border: 1px solid #707070;

		svg {
			margin: 10px;
			transition: all 300ms;
		}
	}
}

.admin-event-list-values {
	font-weight: 300;
	padding: 0 30px;

	thead {
		font-weight: 500;
	}

}

.table-btn {
	padding: 0;
	border: 1px solid #707070;
	background-color: transparent;
	padding: 0.25rem 0.75rem;
	font-weight: 300;
}

.exclude-btn {

}

.display-btn {

}

.admin-event-btn-wrapper {
	display: flex;
	flex-flow: row nowrap;
	gap: 10px;
}

// Feedback
.admin-feedback {
	color: #159b41;
	height: 20px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
}

// Feedback modal root
.admin-feedback-wrapper {
	position: absolute;
	right: 50%;
	left: 50%;
	bottom: 0;
}

.slide-up {
	transition: height 300ms ease-out;
	height: 150px;
}

.slide-down {

}

.applications-wrapper {
	ul {
		list-style: none;
		padding: 0;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 20px;
	}	
	li {
		display: flex;
		flex-flow: column nowrap;
		border: 1px solid #707070;
		border-radius: 8px;

		div:first-child {
			padding: 0.5em;

			h3 {
				margin-top: 0;
				border-bottom: 1px solid #707070;
			}
			
			span {
				p:first-child {
					margin: 0;
					font-size: 0.8em;
				}
				p:last-child {
					margin-top: 0;
					font-size: 0.9em;
				}
			}
		}
		
		div:last-child {
			display: flex;
			justify-content: space-between;

			button {
				width: 50%;
			}
		}
	}
}

.admin-approve-btn {
	background-color: #B4D8AD;
	border-bottom-left-radius: 8px;
}
.admin-reject-btn {
	background-color: #f32013;
	border-bottom-right-radius: 8px;
}

.admin-nav {
	margin: 0.5em 0 0 0;
	display: flex;
	flex-flow: column nowrap;
	row-gap: 10px;

	button {
		flex: 1 1 50px;
		background-color: #B4D8AD;
		border: 1px solid #707070;
		border-radius: 6px;
	}
}


// Admin login
.login-content-wrapper {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.admin-login-form {
	div {
		display: flex;
		flex-flow: column nowrap;
		margin: 1em 0;

		label {
			font-weight: 300;
			font-size: 0.9em;
		}

		input {
			height: 1.5rem;
			font-size: 1rem;
		}
	}
}

.login-btn {
	background-color:#aadaa9;
	border-radius: 6px;
	margin: 1em 0;
	font-weight: 300;
	font-size: 0.9em;
	border: 1px solid #000;
}

// organizer fb login
.facebook-login {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.btn-facebook {
	background-color: #1877F2;
	color: #fff;
	border-radius: 6px;
	padding: 0.5em 0.5em;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	cursor: pointer;

	svg {
		color: #fff;
		font-size: 2em;
		margin-right: 1rem;
	}
}

// month btn
.month-btn {
	cursor: pointer;
}

// Organizer area
.organizer-header {
	margin: 2em 0;
}

.fb-event-form {
	margin: 2em 0;

	form {
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-start;

		label {
			margin: 0.5em 0;
			font-weight: 300;
		}
		input {
			height: 30px;
			font-size: 1em;
			width: 350px;
		}
		button {
			margin-top: 1em;
			background-color: #B4D8AD;
			width: fit-content;
		}
	}

}

.fb-event-current {
	margin: 2em 0 4em 0;

	ul {
		list-style: none;
		padding: 0;
	}
	li {
		background-color: #B4D8AD;
		border: 1px solid #707070;
		padding: 1em 0.5em;
		border-radius: 5px;
	}
	table {
		width: 100%;
	}
}

.fb-event-prev {
	margin: 0 0 4em 0;

	ul {
		list-style: none;
		padding: 0;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 20px;
	}
	li {
		background-color: #fff;
		border: 1px solid #707070;
		padding: 1em 0.5em;
		display: flex;
		flex-flow: column nowrap;
		flex: 1 0 auto;
		border-radius: 8px;

		h3 {
			border-bottom: 1px solid #707070;
		}

		span {

			margin: 0.5em 0;

			p {
				margin: 0;
				font-size: 0.9em;
			}
			p:first-child {
				font-size: 0.8em;
			}
		}

	}
}

// login page
.page-login {
	margin: 8em auto 4em;
	padding: 0 2em;
	min-height: calc(90vh - 300px);
	max-width: 1400px;
}

// Settings page
.page-settings {
	margin: 8em auto 4em;
	padding: 0 2em;
	min-height: calc(90vh - 300px);
	max-width: 1400px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;

	@media screen and (max-width: 900px) {
		flex-flow: column nowrap;
	}

	.settings {
		margin: 1em 0 0 0;
	}
}

.settings-feedback {
	height: 20px;
	margin: 0 0 0.5em 0;
}

.settings-feedback.error {
	color: #f32013;
}

.settings-feedback.success {
	color: #159b41;
}

.admin-settings-toggle {
	order: 2;

	button {
		background-color: #B4D8AD;
		border-radius: 6px;
		border: 1px solid #707070;
	}
}

.settings-content {
	order: 1;

	nav {
		display: flex;
		flex-flow: row nowrap;
		column-gap: 10px;

		@media screen and (max-width: 900px) {
			flex-flow: column nowrap;
		}

		button {
			background-color: #B4D8AD;
			border-radius: 6px;
			border: 1px solid #707070;
		}
	}
}

.settings-change-password {
	display: flex;
	flex-flow: column nowrap;

	h1 {
		margin-bottom: 0;
	}

	form {
		display: flex;
		flex-flow: column nowrap;
		
		div {
			display: flex;
			justify-content: space-between;
			margin: 10px 0 0 0;

			@media screen and (max-width: 900px) {
				flex-flow: column nowrap;
			}
		}
		button {
			margin: 20px 0 0 0;
			background-color: #B4D8AD;
			border: 1px solid #707070;
			border-radius: 6px;
		}
		
	}
}

.settings-create-user {
	display: flex;
	flex-flow: column nowrap;

	h1 {
		margin-bottom: 0;
	}

	form {
		display: flex;	
		flex-flow: column nowrap;

		div {
			display: flex;
			justify-content: space-between;
			margin: 10px 0 0 0;

			@media screen and (max-width: 900px) {
				flex-flow: column nowrap;
			}
		}
		button {
			margin: 20px 0 0 0;
			background-color: #B4D8AD;
			border: 1px solid #707070;
			border-radius: 6px;
		}
	}
}


/////////////////////////////
/// Event Calender
////////////////////////////

.event-filters {
	display: flex;
	flex-flow: row nowrap;
	gap: 1rem;
	justify-content: center;
	max-height: 50px;

	section {
		min-width: 210px;
		display: flex;
		gap: 1rem;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;

		@media screen and (max-width: 900px) {
			flex-flow: column nowrap;
			align-items: center;
			gap: .5rem;
		}	

		.react-datepicker-wrapper {
			.react-datepicker__input-container {
				height: 40px;

				.event-datepicker {
					border: unset;					
					font-size: 20px;
					max-width: 210px;
					font-family: "Poppins";
					height: 100%;
					padding: 0 10px;
					border-radius: 30px;
					border: 1px solid hsl(0, 0%, 80%);

					@media screen and (max-width: 900px) {
						width: 210px;
						padding: 0 10px;
						font-size: 16px;
					}
				}
			}

			@media screen and (max-width: 900px) {
				margin-top: 8px;
			}
		}

		.event-location-filter {
			height: 100%;
			min-width: 210px;

			.event-location__control {
				height: 42px;
				border-radius: 30px;
				border-color: hsl(0, 0%, 80%);
			};

			@media screen and (max-width: 900px) {
				margin-top: 4px;
				width: 100%;
			}
		}
	}
}

.event-navigation {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	margin: 2rem 0;
	gap: 1.563rem;

	h2 {
		min-width: 300px;
		text-align: center;

		@media screen and (max-width: 900px){
			min-width: unset;	
		}
		@media screen and (max-width: 400px){
			font-size: 16px;
		}
	}

	button {
		background-color: unset;
		font-size: 1.5em;
	}
}

.event-items-container {
	display: grid;
	grid-template-rows: repeat(6, 1fr);
}

.event-pagination {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;

	button {
		background-color: unset;

		svg {
			font-size: 1.75em;
			margin: 0 1.25rem;
		}
	}

	.pagination-page {
		color: hsl(0, 0%, 50%);
		font-size: 20px;
		margin: 0.5rem 0.5em;
		width: 2rem;

		@media screen and (max-width: 900px) {
			display: none;
		}
	}
	.pagination-page.active {
		color: hsl(0, 0%, 0%);

		@media screen and (max-width: 900px) {
			display: block;
		}
	}

	.pagination-hidden {
		display: none;
	}

	.pagination-last-page::before {
		display: inline-block;
	}

	.pagination-first-page::after {
		display: inline-block;
	}
}

.pagination-number-wrapper {
	min-width: 450px;
	display: flex;
	justify-content: center;

	@media screen and (max-width: 900px) {
		min-width: 50px;
	}
}

.no-events {
	margin: 2rem 0 4rem;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;

	button {
		width: fit-content;
	}
}

// Single event
.single-event-article {
	margin: 0 200px 2.188rem;

	@media screen and (max-width: 900px) {
		margin: 0;
	}
}

.single-event-header {
	display: flex;
	flex-flow: row nowrap;
	color: #fff;
	justify-content: center;
	margin: 0 auto 4.375rem;
	column-gap: 2.188rem;

	@media screen and (max-width: 900px) {
		flex-flow: column;
		align-items: center;
		margin: 0 auto 2.188rem;
	}
}
  
.single-event-content {
	// padding: 0 4em;
}
  
@media screen and (max-width: 900px) {
	.single-event-content {
	  padding: 2em;
	  margin: 0;
	}
}
  

.single-event-wrapper {
	max-width: 800px;
}

.single-event-image-wrapper {
	position: relative;
	min-height: 440px;
	max-height: 440px;
	min-width: 55%;
	max-width: 440px;
	overflow: hidden;
}

.background-image {
	position: absolute;
	top: 0;
	left: 0;
	max-height: 100%;
	max-width: 440px;
	min-width: 100%;
	min-height: 100%;
	object-fit: fill;
	filter: blur(10px);
	transform: scale(1.03);
}

.foreground-image {
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	max-height: 100%;
	max-width: 100%;
	display: block;
	margin: 0 auto;
	object-fit: contain;
	object-position: center;
	height: 100%;
	width: 100%;

	@media screen and (max-width: 900px) {
		display: unset;
	}
}

.single-event-icon {
	height: 41px;
	width: 41px;
	margin-left: -81px;
	margin-right: 40px;
	position: absolute;

	@media screen and (max-width: 900px) {
		margin: 0;
		display: none;
	}
}